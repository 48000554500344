<template>
	<div class="container">
		<div class="is-flex is-align-items-center is-justify-content-center mb-3">
			<figure class="image is-128x128">
				<img src="@/assets/logo.svg">
			</figure>
		</div>
		<div class="columns is-centered">
			<div class="column is-two-fifths">
				<div class="card">
					<header class="card-header">
						<p class="card-header-title">
							<span class="icon"><i class="mdi mdi-lock default"></i></span>
							<span>{{ $t('Sign in') }}</span>
						</p>
					</header>
					<div class="card-content">
						<form @submit.prevent="signIn">
							<b-field :label="$t('Username')" :message="errors.account" :type="{ 'is-danger': errors.account }" label-position="on-border">
								<b-input v-model="account" autocapitalize="none" type="text"></b-input>
							</b-field>
							<b-field :label="$t('Password')" :message="errors.password" :type="{ 'is-danger': errors.password }" label-position="on-border">
								<b-input v-model="password" type="password"></b-input>
							</b-field>
							<div class="field">
								<button :class="{ 'is-loading': isSubmitting }" class="button is-link" type="submit">{{ $t('Sign in') }}</button>
							</div>
							<hr>
							<!-- <div class="has-text-centered is-size-7">
								<router-link to="/recover-password">{{ $t('Recover password') }}</router-link> &middot;
								<router-link to="/sign-up">{{ $t('Sign up') }}</router-link>
							</div> -->
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "SignIn",
	mounted() {
		document.title = this.$t('Sign in') + ' - GIS ANCPI'
		this.$scrollToTop()
	},
	watch: {
		'$i18n.locale'() {
			document.title = this.$t('Sign in') + ' - GIS ANCPI'
		}
	},
	data() {
		return {
			errors: {},
			account: '',
			password: '',
			isSubmitting: false
		}
	},
	methods: {
		isValid() {
			this.errors = {}
			if (!this.account) {
				this.errors.account = this.$t('This field is mandatory!')
			}
			if (!this.password) {
				this.errors.password = this.$t('This field is mandatory!')
			}
			return !Object.keys(this.errors).length
		},
		signIn() {
			if (!this.isValid()) {
				this.$buefy.toast.open({
					duration: 3000,
					message: this.$t('Check the errors in page!'),
					type: 'is-danger',
					position: 'is-bottom'
				})
				return false
			}
			this.isSubmitting = true
			this.$store.dispatch('signIn', {
				userName: this.account,
				password: this.password
			}).then(response => {
				console.log(response);
				this.isSubmitting = false
				this.$router.push('/')
			}).catch(error => {
				console.log(error);
				this.isSubmitting = false
				this.$buefy.toast.open({
					duration: 3000,
					message: this.$t(error.response ? error.response.data.message : error),
					type: 'is-danger',
					position: 'is-bottom'
				})
			})
		}
	}
}
</script>

<style scoped>

</style>
